import { RefFormData } from 'Components/FormComponents';
import s from './strings';

const RULE_ORDER = [
  'required', 'email', 'rangelength', 'digits', 'validateFunction',
];

export class ValidatedForm extends RefFormData {
  constructor({ styleFields = true} = {}) {
    super();

    this._styleFields = styleFields;
  }

  setRules(rules) {
    Object.keys(rules).forEach(name => {
      if (!this.has(name)) {
        throw new TypeError(`Unknown field ${name}`);
      }

      Object.keys(rules[name]).forEach(ruleType => {
        if (!RULE_ORDER.includes(ruleType)) {
          if (!['ignore', 'errorMessage', 'noCheckInteractive'].includes(ruleType)) {
            throw new TypeError(`${name}: unknown rule option ${ruleType}`);
          }
        }
      });
    });

    this.rules = rules;

    this._setupInputs();
  }

  _setupInputs() {
    this._icons = {};

    Object.entries(this.rules).forEach(([ fieldName, def ]) => {
      const { el } = this.fields[fieldName];

      if (el instanceof RefFormData) {
        return;
      }

      if (this._styleFields && this._shouldStyle(el)) {
        const icon = (
          <span class="form-control-feedback" aria-hidden="true" />
        );

        this._icons[fieldName] = icon;

        el.after(icon);
        el.closest('.form-group').classList.add('has-feedback');
      }

      if (el && el.type !== 'checkbox' && !def.noCheckInteractive) {
        el.onblur = () => this.checkInteractive(fieldName, 'blur');
        el.onkeyup = () => this.checkInteractive(fieldName, 'keyup');
      }
    });
  }

  validate() {
    this.errors = [];

    for (let fieldName in this.rules) {
      const errorType = this.checkField(fieldName);
      if (errorType !== true) {
        this.errors.push({
          fieldName,
          errorType,
          message: this.getFieldErrorMessage(fieldName, errorType),
        });
      }

      this._styleField(fieldName, errorType, 'blur');
    }

    return !this.errors.length;
  }

  checkField(fieldName) {
    if (!this.has(fieldName)) {
      throw new TypeError(`Unknown field ${fieldName}`);
    }

    const rules = this.rules[fieldName];

    if (rules.ignore && rules.ignore()) {
      return true;
    }

    return this.checkRules(fieldName);
  }

  checkRules(fieldName) {
    const rules = this.rules[fieldName];
    const value = this.get(fieldName);

    for (let ruleType of RULE_ORDER) {
      if (!(ruleType in rules)) {
        continue;
      }

      const ruleParams = rules[ruleType];
      const result = this[`test_${ruleType}`](ruleParams, value);

      if (typeof result === 'string') {
        return result;
      }

      if (!result) {
        return ruleType;
      }
    }

    return true;
  }

  checkInteractive(fieldName, eventType) {
    const errorType = this.checkField(fieldName);

    this._styleField(fieldName, errorType, eventType);
  }

  _styleField(fieldName, result, eventType) {
    const { el } = this.fields[fieldName];

    if (!this._styleFields || !this._shouldStyle(el)) {
      return;
    }

    const parent = el.parentNode;
    const icon = this._icons[fieldName];

    parent.classList.remove('has-success');
    parent.classList.remove('has-error');

    icon.classList.remove('tbicon-check');
    icon.classList.remove('tbicon-cross');

    if (result === true) {
      parent.classList.add('has-success');
      icon.classList.add('tbicon-check');

      return;
    }

    if (eventType !== 'blur')
      return;

    parent.classList.add('has-error');
    icon.classList.add('tbicon-cross');
  }

  _shouldStyle(el) {
    if (!el)
      return false;

    if (el instanceof RefFormData || el instanceof HTMLSelectElement ||
        (el instanceof HTMLInputElement && el.type === 'checkbox')) {
      return false;
    }

    return true;
  }

  getFieldErrorMessage(name, errorType) {
    if (!this.has(name)) {
      throw new TypeError(`Unknown field ${name}`);
    }

    let { label } = this.fields[name];
    const { errorMessage } = (this.rules[name] || {});
    if (label.charAt(label.length - 1) === '*') {
      label = label.substring(0, label.length - 1);
    }

    if (errorMessage) {
      return errorMessage;
    }

    const errorTypeKey = errorType === 'required'
      ? 'required'
      : 'invalid';
    const prefix = s.ValidatedForm[`${errorTypeKey}_prefix`];
    const suffix = s.ValidatedForm[`${errorTypeKey}_suffix`];
    return `${prefix}${label}${suffix}`;
  }

  // Rule test methods

  test_required(params, value) {
    const optional = typeof params === 'function'
      ? !params()
      : !params;

    if (optional) {
      return true;
    }

    return !!(typeof value == 'string' ? value.length : value);
  }

  test_email(params, value) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(value);
  }

  test_rangelength([ min, max ], value) {
    return (value.length >= min && value.length <= max);
  }

  test_digits(params, value) {
    return /^\d+$/.test(value);
  }

  test_validateFunction(func, value) {
    return func(value);
  }
}
