import { hide, show, toggleClass, emptyNode } from 'Components/domHelpers';
import { FormText, Cond } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import HookedAlert from 'Components/HookedAlert';

import { ValidatedForm } from './ValidatedForm';

import {
  LINK_STATE_COLLECT_INFO,
  LINK_STATE_COLLECT_PHONE_NUMBER,
  LINK_STATE_CONNECTING,
  LINK_STATE_CONNECTED,
  LINK_STATE_INACTIVE
} from './LinkController';

import s from './strings';
import errors from './errors';

export function LinkPage({ ctrl }) {
  const hooks = new Hooks();
  ctrl.on('update', () => hooks.run(ctrl));

  const ctx = {
    hooks,
    ctrl,
  };

  const imgWrapper = img => {
    return img.cloneNode(true);
  };

  return (
    <div
      class="link-page"
      use:hook={hooks.toggleClass('state', 'is-active', state => state !== LINK_STATE_INACTIVE)}
      use:hook={hooks.toggleClass('hasStatusMessage', 'has-status-message')}
    >
      <div class="info-panel">
        <div class="logo-header-container">
          <Cond test={ctrl.logoHeader}>
            {imgWrapper(ctrl.logoHeader)}
          </Cond>
          <div
            class="welcome-message-mobile"
            use:hook={hooks.hide('state', state => state === LINK_STATE_INACTIVE)}
          >
            <WelcomeMessage ctx={ctx} />
          </div>
        </div>

        <p class="conference-code mobile-only">{s.conferenceCodeLabel} {ctrl.conferenceIDFormatted}</p>

        <div
          class="welcome-message-container desktop-only"
          use:hook={hooks.show('state', state => state === LINK_STATE_COLLECT_INFO || state === LINK_STATE_COLLECT_PHONE_NUMBER)}
        >
          <div class="welcome-message">
            <WelcomeMessage ctx={ctx} />
          </div>
          <p class="conference-code">{s.conferenceCodeLabel} {ctrl.conferenceIDFormatted}</p>
        </div>

        <div
          class="status-message"
          use:hook={hooks.show('hasStatusMessage')}
        >
          <p use:hook={hooks.show('state', state => state === LINK_STATE_INACTIVE)}>
            {s.inactiveMessage}
          </p>

          <p use:hook={hooks.show('state', state => state === LINK_STATE_CONNECTING)}>
            {s.connectingMessage}
            {' '}
            <ConnectingImage />
          </p>

          <p use:hook={hooks.show('state', state => state === LINK_STATE_CONNECTED)}>
            {s.connectionMessageLine1}
          </p>
          <p use:hook={hooks.show('state', state => state === LINK_STATE_CONNECTED)}>
            {s.connectionMessageLine2}
          </p>
        </div>

        <Cond test={ctrl.logoFooter}>
          <div class="logo-footer-container">
            {imgWrapper(ctrl.logoFooter)}
          </div>
        </Cond>
      </div>

      <UserInfoForm ctx={ctx} />
      <PhoneNumberForm ctx={ctx} />

      <Cond test={ctrl.logoFooter}>
        <div class="logo-footer-container-mobile">
          {imgWrapper(ctrl.logoFooter)}
        </div>
      </Cond>
    </div>
  );
}

function UserInfoForm({ ctx: { hooks, ctrl } }) {
  const form = new ValidatedForm();
  let formError;

  const submit = e => {
    e.preventDefault();

    if (!form.validate()) {
      formError.render(form.errors);
      return;
    }

    formError.render(false);

    ctrl.collectInfoSubmit(form.getAllValues());
  };

  const root = (
    <form
      class="form-panel"
      use:hook={hooks.show('state', state => state === LINK_STATE_COLLECT_INFO)}
      onsubmit={submit}
    >
      <div>
        <FormError icon={false} ref={formError} />
        {ctrl.fields.map(field => <FormText inline form={form} name={field.name} label={`${field.label}*`} value={field.initialValue} />)}
      </div>
      <span class="form-note">{s.requiredFields}</span>
      <button type="submit" class="btn btn-primary btn-lg">{s.continue}</button>
    </form>
  );

  const formFields = form.getAllFields();
  let formValidationRules = {};

  formFields.forEach(field => formValidationRules[field.name] = { required: true });
  form.setRules(formValidationRules);

  formError.render(false);

  return root;
}

function PhoneNumberForm({ ctx: { hooks, ctrl } }) {
  const form = new ValidatedForm();
  let formError;

  const submit = e => {
    e.preventDefault();

    if (!form.validate()) {
      formError.render(form.errors);
      return;
    }

    formError.render(false);

    ctrl.callMe(form.get('phoneNumber'));
  };

  const root = (
    <form
      class="form-panel"
      use:hook={hooks.show('state', state => state === LINK_STATE_COLLECT_PHONE_NUMBER)}
      onsubmit={submit}
    >
      <div>
        <HookedAlert hooks={hooks} showProp="callMeErrorCode" textContentProp="callMeErrorCode" textContentTransform={val => errors[val]} />
        <FormError icon={false} ref={formError} />
        <FormText inline form={form} type="tel" name="phoneNumber" label={`${s.phoneNumberLabel}*`} value={ctrl.storedPhoneNumber} />
      </div>
      <span class="form-note">{s.requiredFields}</span>
      <button type="submit" class="btn btn-primary btn-lg">{s.callMe}</button>
    </form>
  );

  form.setRules({
    phoneNumber: {
      required: true,
    },
  });

  formError.render(false);

  return root;
}

function WelcomeMessage({ ctx: { ctrl } }) {
  return (
    <p>
      {s.welcomeMessage}<br />
      {ctrl.linkName}
    </p>
  );
}

function ConnectingImage() {
  return (
    <svg
      class="connecting-image"
      x="0px"
      y="0px"
      viewBox="0 0 50 31.25"
      height="31.25"
      width="50"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        class="connecting-image-track"
        stroke-width="4"
        fill="none"
        pathlength="100"
        d="M0.625 21.5 h10.25 l3.75 -5.875 l7.375 15 l9.75 -30 l7.375 20.875 v0 h10.25"
      />
      <path
        class="connecting-image-car"
        stroke-width="4"
        fill="none"
        pathlength="100"
        d="M0.625 21.5 h10.25 l3.75 -5.875 l7.375 15 l9.75 -30 l7.375 20.875 v0 h10.25"
      />
    </svg>
  );
}

class FormError {
  static isClassComponent = true;

  constructor({ icon = true, hideType = 'hidden', ref }) {
    ref(this);

    this._hideType = hideType;

    this.root = (
      <div classList={{ alert: true, 'alert-danger': true, 'alert-icon': icon }}></div>
    );
  }

  render(error) {
    if (!error || !error.length) {
      this._hide();
      return;
    }

    emptyNode(this.root);

    let singleMessage = false;
    if (Array.isArray(error)) {
      if (error.length === 1) {
        singleMessage = error[0].message;
      }
    } else {
      singleMessage = error;
    }

    if (singleMessage) {
      this.root.textContent = singleMessage;
    } else {
      this.root.appendChild(
        <div>
          <p>{s.FormError.msg}</p>

          <ul>
            {error.map(err => <li>{err.message}</li>)}
          </ul>
        </div>
      );
    }

    this._show();
    window.scrollTo(0, 0);
  }

  _show() {
    switch (this._hideType) {
    case 'hidden':
      show(this.root);
      break;

    case 'invisible':
      toggleClass(this.root, 'invisible', false);
      break;
    }
  }

  _hide() {
    switch (this._hideType) {
    case 'hidden':
      hide(this.root);
      break;

    case 'invisible':
      toggleClass(this.root, 'invisible', true);
      break;
    }
  }
}
